
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Medicine Requests</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-lg-12 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <li @click.prevent="toggleFilters()">
                <button class="dropdown-item" type="button">
                  Toggle Filters
                </button>
              </li>
            </ul>
          </div>
          <button @click.prevent="toggleFilters()" type="button" class="btn btn-primary" >
            <i class="ri ri-filter-2-line align-bottom"></i>  Filters </button>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="input-group w-auto">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select @change="fetchItems()" v-model="defaultStatus" class="form-select flex-grow-1">
              <option value="" disabled selected>-- sort by status --</option>
              <option v-for="(status, tIndex) in statusOptions"
                :value="status.value" :key="tIndex" > {{status.label}} </option>
            </select>
          </div>
        </div>
        <div class="col-md-5">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="">
        <common-portlet headertitle="Filters"
         :visible="enableFilters" :open="true">
          <div class="loading-viewport">
            <div class="card-body p-lg-3">
              <drug-request-filter-form 
              @startSearch="setFilters($event)"
              @resetFilters="initializeAll()" />
            </div>
          </div>
        </common-portlet>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm"  align="middle">
              <template v-slot:cell(id)="data" >
                <div style="min-width:4rem" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.id }}
                </div>
              </template>
              <template v-slot:cell(medicine)="data">
                <div style="min-width:15rem;" v-if="data.item.medicine" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  <strong>{{ data.item.medicine.name }} </strong> - <span v-if="data.item.medicine.type"> 
                    {{ data.item.medicine.type.title}}
                    </span>
                  <br />
                  <small>
                    {{data.item.medicine.description}}
                  </small>
                </div>
              </template>
              <template v-slot:cell(quantity)="data">
                <div style="min-width:4rem" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.quantity }}
                </div>
              </template>
              <template v-slot:cell(tag)="data">
                <div style="min-width:4rem" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.tag }}
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div style="min-width:6rem" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  <span v-html="statusBadge(data.item.status)"></span>
                </div>
              </template>
              <template v-slot:cell(created_at)="data">
                <div style="min-width:6rem;max-width:6rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ $filters.date(data.item.created_at, 1) }}
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="3" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import CommonPortlet from '@/components/portlets/CommonPortlet.vue';
import IsLoading from '@/components/IsLoading.vue';
import DrugRequestFilterForm from '@/components/forms/DrugRequestFilterForm.vue';

export default {
  name: "drug-requests",
  components:{
    IsLoading,
    CommonPortlet,
    DrugRequestFilterForm,
  },
  data() {
    return {
      isLoading: false,
      popupModalShow: false,
      enableFilters: false,
      queryFilters: null,
      searchQuery: "",
      defaultStatus: null,
      statusOptions:[
        { value: null , label: "All" },
        { value: "pending", label: "Pending" },
        { value: "approved", label: "Approved" },
        { value: "denied", label: "Denied" },   
      ],
      pageOptions: [10, 50, 100, 500, 1000],
      columns: [
        {
          key: "id",
          label: "Ref No",
        },
        {
          key: "medicine",
          label: "Medicine",
        },
        {
          key: "quantity",
          label: "Quantity",
        },
        {
          key: "tag",
          label: "Tag",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "created_at",
          label: "Date",
        },
      ],
      pageData:{
        data: []
      }
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
  },
  methods: {
    navigateTo(id){
      this.$router.push({path:`/medicine-requests/${id}`});
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = { page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      if(this.defaultStatus){ payload.status = this.defaultStatus }
      if(this.queryFilters) { Object.assign(payload, this.queryFilters)}
      this.isLoading = true
      this.$http.get(`/medicine-requests?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.pageData = response.data.data
          }
      })
    },
    toggleFilters(){
      this.enableFilters = !this.enableFilters; 
      this.initializeAll()
    },
    setFilters(data){
      this.queryFilters = data
      this.fetchItems()
    },
    initializeAll(){
      this.queryFilters = null;
    }
  },
  mounted() {
    this.fetchItems()
  },
}
</script>

